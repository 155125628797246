:root {
  // Warm
  --background-col: snow;
  --sheet-col: LavenderBlush;
  --highlight-col: pink;
  // Purples
  // --background-col: ghostwhite;
  // --sheet-col: lavender;
  // --highlight-col: thistle;
  // Yellows
  // --background-col: ivory;
  // --sheet-col: papayawhip;
  // --highlight-col: tan;
  // Cold
  // --background-col: ghostwhite;
  // --sheet-col: aliceblue;
  // --highlight-col: powderblue;

  --important-col: indianred;
  --light-gray-col: lightgray;
  --mid-gray-col: darkgray;
  --dark-gray-col: dimgray;
  --text-col: rgba(30, 30, 30, 0.8);
  // --font-family: "Roboto Mono", monospace;
  --baseline-font-size: 16px;
}

@font-face {
  font-family: "Overpass Mono";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Overpass Mono"), local("Overpass Mono Regular"),
    local("OverpassMono-Regular"),
    url("/fonts/overpass-mono-v5-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("/fonts/overpass-mono-v5-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

:root {
  --font-family: "Overpass Mono", monospace;
}

* {
  font-family: var(--font-family);
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

body {
  margin: 0;
  overflow: hidden;
  // overflow: -moz-hidden-unscrollable;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  background-color: var(--background-col);
}

input {
  box-sizing: border-box;
  margin: 4px;
  padding: 4px;
  height: 32px;
  width: 200px;
  min-width: 200px;
  max-width: 400px;
  font-family: var(--font-family);
  color: var(--text-col);
  border-style: solid;
  border-width: 1px;
  border-radius: 0px;
  border-color: var(--light-gray-col);

  &:read-only {
    filter: brightness(90%);
  }
}
button {
  box-sizing: border-box;
  margin: 4px;
  padding: 4px;
  height: 32px;
  min-width: 200px;
  max-width: 400px;
  font-family: var(--font-family);
  font-size: 1em;
  color: var(--text-col);
  background-color: var(--highlight-col);
  border-style: solid;
  border-width: 1px;
  border-radius: 0px;
  border: solid 1px var(--mid-gray-col);
  box-shadow: 0px 0px 5px 0px var(--light-gray-col);
  position: relative;

  &:hover:not(:disabled) {
    filter: brightness(110%);
  }

  &.important {
    background-color: var(--important-col);
    color: var(--light-gray-col);
  }

  &:disabled {
    filter: brightness(80%);
    box-shadow: none;
  }
}

// SHEETS
.sheet {
  border: solid 1px var(--light-gray-col);
  box-shadow: 0px 0px 10px 0px var(--light-gray-col);
  box-sizing: border-box;
  color: var(--text-col);
  position: relative;
  background-color: var(--sheet-col);

  &:before,
  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    box-sizing: border-box;
    background-color: var(--sheet-col);
    border: solid 1px var(--light-gray-col);
    box-shadow: 0px 0px 10px 0px var(--light-gray-col);
  }

  &:before {
    transform: rotate(0.5deg) translate(-5px, -0px);
  }

  &:after {
    transform: rotate(-1deg) translate(-5px, 0px);
  }

  hr {
    width: 90%;
    opacity: 0.8;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  @media screen and (max-width: 600px), screen and (max-height: 600px) {
    box-shadow: none;

    &:before {
      content: none;
    }
    &:after {
      content: none;
    }
  }
}
::-webkit-scrollbar {
  width: 2vw;
  min-width: 8px;
  max-width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--highlight-col);
  height: 50%;
  border: 2px solid var(--sheet-col);
}

// *********** MAIN PAGE  *********** //

// This is the google one tap sign in dialog. We hide it initially.
div#credential_picker_container {
  display: none;
  // top: unset;
  // bottom: 0px;
  // left: unset;
  // right: unset;
}
@media screen and (max-width: 600px), screen and (max-height: 600px) {
  div#credential_picker_container {
    top: unset;
    bottom: 0px;
    left: 50%;
    right: unset;
    transform: translate(-50%, 0%);
  }
}

// A full page overlay in which to position ui elements
.hud-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
  pointer-events: none;
  * {
    pointer-events: all;
  }
}

// NOTEPAD EDITOR
main {
  width: 100%;
  height: 100%;
}

#editor-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  noscript {
    position: absolute;
    bottom: 10vh;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 999;
    background-color: var(--important-col);
    margin: 0px;
    max-width: 100%;
    width: 60ch;
    text-align: center;
  }

  #editor-sheet {
    padding-left: calc(8px + 3vw);
    padding-right: calc(8px + 3vw);
    padding-top: calc(8px + 3vh);
    padding-bottom: calc(8px + 3vh);
    margin: 0%;
    height: 90vh;
    width: 90vw;

    max-width: 100ch;
    box-sizing: border-box;

    textarea {
      margin: 0;
    }
  }
  #editor {
    padding-left: calc(8px + 3vw);
    padding-right: calc(8px + 3vw);
    padding-top: calc(8px + 3vh);
    padding-bottom: calc(8px + 3vh);
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    resize: none;
    font-family: var(--font-family);
    font-size: var(--baseline-font-size);
    outline: none;
    position: absolute;
    top: 0;
    left: 0;
  }
  #editor::placeholder {
    color: var(--text-col);
    opacity: 0.7;
    font-size: 18px;
  }

  @media screen and (max-width: 600px), screen and (max-height: 600px) {
    #editor-sheet {
      width: 100%;
      height: 100%;
    }
    #editor {
      padding-bottom: 0px;
    }
  }
}

#toolbar-container {
  padding: 0px;
  padding-right: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;

  .icon-link,
  .icon-btn {
    width: 48px;
    height: 48px;
    color: var(--highlight-col);
    margin: 4px;
  }

  .icon-btn {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    font-family: sans-serif;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  .icon-btn:hover,
  .icon-link:hover {
    filter: brightness(115%);
  }

  @media screen and (max-width: 600px), screen and (max-height: 600px) {
    .icon-link,
    .icon-btn {
      width: 32px;
      height: 32px;
      padding: 2px;
    }
  }
}

#user-info {
  * {
    position: absolute;
    bottom: 0;
    width: 60%;
    text-align: center;
    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: 8px;
    color: var(--dark-gray-col);
  }
  @media screen and (max-width: 600px), screen and (max-height: 600px) {
    display: none;
  }
}

#status-bar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1px;
  background-color: var(--background-col);
  color: var(--mid-gray-col);
  border-top: solid 1px var(--light-gray-col);
  flex-wrap: wrap;
  position: absolute;
  bottom: 0;

  * {
    font-size: 16px;
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    border-left: solid 1px var(--dark-gray-col);
  }
  *:first-child {
    padding-left: 0px;
    border-left: none;
  }
  .status-good {
    color: darkgreen;
  }
  .status-pending {
    color: darkgoldenrod;
  }
  .status-bad {
    color: darkred;
  }
  .status-disabled {
    color: darkgrey;
  }

  @media screen and (max-height: 300px) {
    #status-bar {
      display: none;
    }
  }
}

// *********** ACCOUNT PAGE  *********** //
#account-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  #account-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 60ch;
    padding-left: calc(8px + 3vw);
    padding-right: calc(8px + 3vw);
    padding-top: calc(8px + 3vh);
    padding-bottom: calc(8px + 3vh);

    #active-account-section,
    #marked-for-deletion-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 60ch;
    }

    * {
      margin: 8px;
      text-align: center;
    }
  }

  #deletion-message {
    color: var(--important-col);
    // overflow-wrap: break-word;
  }

  @media screen and (max-width: 600px), screen and (max-height: 600px) {
    #account-details {
      width: 100%;
      height: 100%;
      max-width: none;
      justify-content: flex-start;
    }
    #account-page {
      height: 100%;
      width: 100%;
    }
  }
}

// *********** SIGNIN PAGE  *********** //
#signin-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;

  #signin-sheet {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 50ch;
    padding-left: calc(8px + 3vw);
    padding-right: calc(8px + 3vw);
    padding-top: calc(8px + 3vh);
    padding-bottom: calc(8px + 3vh);

    input {
      text-align: start;
    }

    .firebaseui-idp-button {
      // Minor tweaks to firebaseui login buttons
      max-width: 320px; // increased from 220px;
      font-weight: bold;
    }
  }
  #firebaseui-auth-container {
    min-height: 200px; // placeholder height, until external ui elements have been loaded
  }
}

@media screen and (max-width: 600px), screen and (max-height: 600px) {
  #signin-page {
    justify-content: flex-start;

    #signin-sheet {
      width: 100%;
      height: 100%;
      max-width: 100%;
      // justify-content: flex-start;
      overflow-y: auto;
    }
    #firebaseui-auth-container
      > .firebaseui-container:not(.firebaseui-page-provider-sign-in) {
      background-color: var(--sheet-col);
      color: var(--text-col);
      width: 90vw;
    }
  }
}

// *********** REVISIONS PAGE  *********** //
#revisions-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  #revision-outer-sheet {
    box-sizing: border-box;
    height: 90vh;
    width: 90vw;
    max-width: 100ch;

    padding-left: calc(8px + 3vw);
    padding-right: calc(8px + 3vw);
    padding-top: calc(8px + 3vh);
    padding-bottom: calc(8px + 3vh);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    * {
      margin: 8px;
    }

    .revision-card {
      white-space: pre-line;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      max-width: 100ch;
      overflow-y: auto;
      overflow-x: hidden;
      font-size: 0.9em;
    }

    #revision-container {
      width: 100%;
      height: 100%;
      max-width: 1000px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow-y: auto;
    }

    #revision-slider {
      width: 100%;
      -webkit-appearance: none;
      height: 16px;
      padding: 0px;
      background: rgba(211, 211, 211, 0.7);

      // margin: 24px;
      outline: none;
    }

    #revision-slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 96px;
      height: 16px;
      background: var(--highlight-col);
      cursor: pointer;
      border-radius: 0;
      border: none;
    }
    #revision-slider::-moz-range-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 96px;
      height: 16px;
      background: var(--highlight-col);
      cursor: pointer;
      border-radius: 0;
      border: none;
    }
  }
  @media screen and (max-width: 600px), screen and (max-height: 600px) {
    #revision-outer-sheet {
      width: 100%;
      height: 100%;
      padding: 4px;
      box-sizing: border-box;
    }
  }
}

// *********** HELP PAGE  *********** //
#help-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  #help-page-sheet {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
    width: 90vw;
    max-width: 100ch;
    padding: 0px;

    position: relative;

    #help-page-contents {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;
      width: 100%;
      padding-left: calc(8px + 3vw);
      padding-right: calc(8px + 3vw);
      padding-top: calc(8px + 3vh);
      padding-bottom: calc(8px + 3vh);
      box-sizing: border-box;

      * {
        margin: 8px;
        text-align: center;
      }

      #send-message-form {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        box-sizing: border-box;

        > div {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          width: 100%;
          margin: 0;

          label {
            width: 200px;
            min-width: 200px;
          }
          * {
            min-width: 200px;
            width: 400px;
          }
          textarea {
            height: 80px;
            text-align: start;
            border: solid 1px var(--light-gray-col);
            resize: vertical;
          }
        }

        button {
          margin: auto;
        }
      }

      table {
        width: 100%;
        max-width: 80ch;
        text-align: center;
        border-collapse: collapse;
      }

      td,
      th {
        max-width: 50px;
        border: 1px solid var(--dark-gray-col);
        margin: 0;
        border-collapse: collapse;
      }
    }
  }
  @media screen and (max-width: 600px), screen and (max-height: 600px) {
    justify-content: flex-start;

    #help-page-sheet {
      width: 100%;
      height: unset;
    }
  }
}
